@import 'colors';
@import 'fonts';

@mixin base-text {
  color: $color-text-dark;
  font-family: 'TheSansE5-Plain';
}

@mixin base-spacing {
  margin: 0;
  padding: 0;
}

@mixin headline5 {
  @include base-text();
  @include base-spacing();
  font-weight: 700;
  font-style: bold;
  font-size: px2r(16);
  line-height: px2r(20);
}

.headline1 {
  @include base-text();
  font-weight: 700;
  font-style: bold;
  font-size: px2r(69);
  line-height: px2r(72) !important;
}

@mixin headline2 {
  @include base-text();
  font-weight: 700;
  font-style: bold;
  font-size: px2r(48);
  line-height: px2r(52) !important;
}

.headline2 {
  @include headline2();
}

.headline3 {
  @include base-text();
  font-weight: 700;
  font-size: px2r(23);
  line-height: px2r(26) !important;
}

.headline5 {
  @include headline5();
}

h1,
.h1 {
  @include base-text();
  @include base-spacing();
  font-weight: 700;
  font-style: bold;
  font-size: px2r(33);
  line-height: px2r(36);
}

@mixin h2 {
  @include base-text();
  @include base-spacing();
  font-weight: 700;
  font-style: normal;
  font-size: px2r(28);
  line-height: px2r(32);
}

h2,
.h2 {
  @include h2();
}

@mixin h3 {
  @include base-text();
  @include base-spacing();
  font-weight: 700;
  font-style: bold;
  font-size: px2r(23);
  line-height: px2r(28);
}

h3,
.h3 {
  @include h3();
}

.h4,
h4 {
  @include base-text();
  @include base-spacing();
  font-weight: 700;
  font-style: bold;
  font-size: px2r(19);
  line-height: px2r(24);
}

.h5,
h5 {
  @include headline5();
}

h6 {
  @include base-text();
  @include base-spacing();
  font-weight: 700;
  font-style: bold;
  font-size: px2r(13);
  line-height: px2r(16);
}

.big {
  @include font-basic();
  font-size: px2r(19);
  line-height: px2r(31);
}

p {
  @include font-basic();
  line-height: px2r(26) !important;
  padding: 0;
  margin: 0;

  &.text--secondary {
    font-style: normal;
    font-weight: 500;
    font-size: px2r(16);
    line-height: px2r(26);
    color: $color-text-secondary;
  }
}
