@use '@ista-ui/angular';
@use '@ista-ui/styles/preflight.css';
@use 'material-symbols';

:root {
  // electricity Colors Start
  --electricity-800: #F09000;
  --electricity-500: #F0AC00;
  --electricity-500-50: rgba(240, 172, 0, 0.50);
  --electricity-500-20: rgba(240, 172, 0, 0.20);
  // electricity Colors End

  // gas Colors Start
  --gas-800: #75339D;
  --gas-500: #8E33C6;
  --gas-500-50: rgba(142, 51, 198, 0.50);
  --gas-500-20: rgba(142, 51, 198, 0.20);
  // gas Colors End

  // water Colors Start
  --water-800: #458CDF;
  --water-500: #80B7F9;
  --water-500-50: rgba(128, 183, 249, 0.50);
  --water-500-20: rgba(128, 183, 249, 0.20);
  // water Colors End

  // waste Colors Start
  --waste-800: #003C32;
  --waste-500: #406D65;
  --waste-500-50: rgba(64, 109, 101, 0.50);
  --waste-500-20: rgba(64, 109, 101, 0.20);
  // waste Colors End

  // cold Colors Start
  --cold-800: #01BEBE;
  --cold-500: #01D3D3;
  --cold-500-50: rgba(1, 211, 211, 0.50);
  --cold-500-20: rgba(1, 211, 211, 0.20);
  // cold Colors End

  // heating Colors Start
  --heating-800: #D94444;
  --heating-500: #FF5050;
  --heating-500-50: rgba(255, 80, 80, 0.50);
  --heating-500-20: rgba(255, 80, 80, 0.20);
  // heating Colors End

  // cost Colors Start
  --cost-800: #0047A1;
  --cost-500: #0065E4;
  --cost-500-50: rgba(0, 101, 228, 0.50);
  --cost-500-20: rgba(0, 101, 228, 0.20);
  // cost Colors End

  // co2 Colors Start
  --co2-800: #13A45B;
  --co2-500: #44C584;
  --co2-500-50: rgba(68, 197, 132, 0.50);
  --co2-500-20: rgba(68, 197, 132, 0.20);
  // co2 Colors End

  // biomass Colors Start
  --biomass-800: #382D80;
  --biomass-500: #4C3CB0;
  --biomass-500-50: rgba(76, 60, 176, 0.50);
  --biomass-500-20: rgba(76, 60, 176, 0.20);
  // biomass Colors End

  // other Colors Start
  --other-800: #C430DC;
  --other-500: #DA35F5;
  --other-500-50: rgba(218, 53, 245, 0.50);
  --other-500-20: rgba(218, 53, 245, 0.20);
  // other Colors End

  // neutral Colors Start
  --neutral-800: #475569;
  --neutral-500: #64748B;
  --neutral-500-50: rgba(100, 116, 139, 0.50);
  --neutral-500-20: rgba(100, 116, 139, 0.20);
  // neutral Colors End

  // oil Colors Start
  --oil-800: #571B1B;
  --oil-500: #823C3C;
  --oil-500-50: rgba(130, 60, 60, 0.50);
  --oil-500-20: rgba(130, 60, 60, 0.20);
  // oil Colors End

  --heating-district-800: #D94444;
  --heating-electricity-800: #F09000;

  --crrem-line: #15B665;
  --crrem-line-portfolio: #1E293B;
  --crrem-stranding-circle: #DA1313;
}

@tailwind utilities;

@import "./assets/styles/base.scss";

@layer base {
  html {
    font-family: "TheSansE5-Plain", Arial, serif;
  }

  .w-main-navigation { // please don't it delete unless U KNOW WHAT TO DO
    max-width: calc(var(--contentMax) * 1px);
    width: calc(100% - var(--navigationWidth) * 1px);
  }

  .w-main-navigation-tablet { // please don't it delete unless U KNOW WHAT TO DO
    max-width: calc(var(--contentMaxTablet) * 1px);
    width: calc(100% - var(--navigationWidthTablet) * 1px);
  }

  .w-sub-navigation { // please don't it delete unless U KNOW WHAT TO DO
    max-width: calc(var(--contentMaxSmall) * 1px);
    width: calc(100% - (var(--navigationSubMenuWidth) + var(--navigationWidthSmall)) * 1px);
  }


  .material-icons {
    font-family: 'Material Symbols Outlined', 'Material Icons', sans-serif !important;
  }
}

.ista-button {
  .mdc-button__label {
    @apply flex items-center;
  }

  // @TODO Delete Button Style when Time comes, START
  // this style to make ista-button looks like daytona Button
  @apply min-h-12 max-h-12 min-w-[154px] rounded-lg font-bold text-base leading-[48px] py-0 px-5 #{!important};
  &.mdc-button--outlined {
    border-width: 2px;
  }

  // @TODO END

  &--small {
    @apply min-w-0 font-medium h-6 leading-none max-h-6 min-h-6 px-4 py-1 rounded text-xs #{!important};
  }
}

// we need to refactor button.scss and mixin to apply Primary,..etc Style to different Element
a.btn-primary {
  background-color: #0071f0;
  color: #fff;
  min-width: 9.625rem;
  height: 3rem;
  border-radius: 0.5rem;
  font-family: "TheSansE5-Plain", serif;
  font-weight: 700;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: unset;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0.125)), #0071f0;
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #0071f0;
  }
}

// this Style related to deepLinking
// separation the style from the Implementation is here required to have more Customization
.deep-link-text {
  @apply hover:text-primary-500 #{!important};
}

.deep-link-card {
  @apply hover:shadow-card;
}

//

.unsaved-changes-dialog {
  .dialog-header-container {
    > .mat-icon {
      @apply text-warn
    }
  }
}
