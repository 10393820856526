@import 'colors';

.card-container {
	background: $color-white;
	border: 1px solid $color-bg-medium;
	border-radius: 6px;
}

.card-color-container {
	background: $color-bg-light;
	border: 1px solid $color-bg-semilight;
	border-radius: 4px;
}
