@import "./index.scss";

html {
  font-size: px2r(16);
}

body {
  background-color: $color-bg-body;
  margin: 0;
  @include font-basic();
}

html,
body {
  height: 100%;
  overflow-x: clip;
}

.text {
  margin: 0px;
  padding: 0px;
  @include font-basic();
  max-width: 805px;

  &.small {
    font-size: px2r(13);
    line-height: px2r(20);
  }

  &.secondary-text {
    color: $color-text-secondary;
  }

  &.tertiary-text {
    color: $color-text-tertiary;
  }

  &.full {
    max-width: none;
  }

  &.bold {
    font-weight: 530;
  }
}

ul {
  padding-inline-start: px2r(22);
  list-style-type: square;
}

sub,
sup {
  font-size: 50% !important;
}

.overlay-background {
  background-color: rgba(0, 0, 0, 0.5);
}

.error {
  color: $color-error;
}

.result-finding {
  background-color: #fdec9b;
}

.page--static {
  margin-top: 68px;
}

.icon {
  width: 24px;
  height: 24px;
}

.no-break {
  white-space: nowrap;
}

.value-input-container {
  width: 200px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  max-height: 240px;

  &.large {
    width: 250px;
  }
}
