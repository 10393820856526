:root {
  --maxContentWidth: 1920;
  --navigationWidth: 240;
  --navigationWidthTablet: 128;
  --navigationWidthSmall: 64;
  --navigationSubMenuWidth: 288;
  --contentMax: calc(var(--maxContentWidth) - var(--navigationWidth));
  --contentMaxTablet: calc(var(--maxContentWidth) - var(--navigationWidthTablet));
  --contentMaxSmall: calc(
    var(--maxContentWidth) - var(--navigationWidthSmall) -
    var(--navigationSubMenuWidth)
  );
}

@mixin page-layout {
  display: grid;
  grid-template-columns: calc(var(--navigationWidth) * 1px) minmax(
      600px,
      calc(var(--contentMax) * 1px)
    );
}

@mixin page-layout-submenu {
  display: grid;
  grid-template-columns:
    calc(var(--navigationWidthSmall) * 1px) calc(
      var(--navigationSubMenuWidth) * 1px
    )
    minmax(600px, calc(var(--contentMaxSmall) * 1px));
}

.navigation-width--small {
  width: calc(var(--navigationWidthSmall) * 1px) !important;
}

.navigation-width {
  width: calc(var(--navigationWidth) * 1px) !important;
}
