@import "./colors.scss";
@import "./fonts.scss";
@import "./disabled.scss";
@import "./helper.scss";

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button-flat {
  padding: 0px !important;
}

.button-dropdown {
  background-color: $color-bg-body;
  height: 40px;
  line-height: 40px;
  padding: 0px 16px;
  @include items-center();
  display: flex;
  gap: 8px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: $color-cta;
  }
}

.button-select {
  border-radius: 6px;
  display: flex;
  gap: 4px;
  @include items-center();
  cursor: pointer;
  background: $color-bg-body;
  height: 40px;
  max-width: 224px;

  img {
    height: 24px;
    width: 24px;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.button-group {
  display: flex;
  gap: 16px;

  &--horizontal {
    &.offset-right {
      ruf-button {
        margin-right: 20px;
      }
    }

    &.right {
      justify-content: flex-end;
      ruf-button {
        margin-right: 0px;
      }
    }
  }

  &--vertical {
    display: block;
  }
}

@mixin button-font {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: $color-white;
}

@mixin button-base {
  border: 0px !important;
  border-radius: 6px !important;
  padding: px2r(14) px2r(16);
  line-height: 1;
  min-width: fit-content;
  max-height: unset;
  position: relative;
  cursor: pointer;
}

@mixin button-disabled {
  opacity: 1 !important;
  background-color: $color-grey-03 !important;
  color: $color-grey-05 !important;
  cursor: not-allowed;
}

.button--white {
  @include items-center();
  cursor: pointer;
  background: $color-bg-body;
  height: 40px;
  min-width: 40px;

  img {
    height: 24px;
    width: 24px;
  }
}

.button--link {
  background-color: $color-bg-body;
  border-radius: 6px;
  height: 64px;
  line-height: 40px;
  padding-inline: unset !important;
  @include items-center();
  @include box-shadow();

  &-flat {
    box-shadow: unset;
    border-radius: 0;
  }

  &-content {
    display: flex;
    gap: 8px;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;

    &.padding--large {
      padding-left: 16px;
    }

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: $color-cta;
    }
  }

  &.small {
    font-size: 16px;
    font-weight: 530;
  }

  &:hover {
    color: $color-link;
  }

  &:active,
  &:focus {
    background: linear-gradient(
        0deg,
        rgba(0, 113, 240, 0.125),
        rgba(0, 113, 240, 0.125)
      ),
      $color-bg-body;
    outline-color: $color-cta;
  }

  &.disabled {
    @include disabled();
    color: $color-button-text-disabled !important;

    &:hover {
      background: unset !important;
    }

    &:active {
      background: transparent;
    }
  }
}
