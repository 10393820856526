@mixin grid($count: 12) {
  display: grid;
  grid-template-columns: repeat($count, 96px);
  column-gap: 24px;
}

@mixin gridTrack($start, $end) {
  grid-column-start: $start;
  grid-column-end: $end;
}

@mixin gridGaps {
  &.grid--gap-0 {
    row-gap: 0 !important;
    column-gap: 0 !important;
  }

  &.grid--gap-4 {
    row-gap: 4px !important;
    column-gap: 4px !important;
  }

  &.grid--gap-8 {
    row-gap: 8px !important;
    column-gap: 8px !important;
  }

  &.grid--gap-12 {
    row-gap: 12px !important;
    column-gap: 12px !important;
  }

  &.grid--gap-16 {
    row-gap: 16px !important;
    column-gap: 16px !important;
  }

  &.grid--gap-44 {
    row-gap: 44px !important;
    column-gap: 44px !important;
  }
}

.grid-flex {
  display: flex !important;

  @include gridGaps();
}

@mixin grid($args) {
  .grid {
    display: grid !important;
    grid-template-columns: #{$args};
    row-gap: 24px;
  }
}

.grid {
  display: grid !important;
  grid-template-columns: 1fr;
  row-gap: 24px;

  $grid-columns: 12;
  @for $i from 1 through $grid-columns {
    &.grid--col-#{$i} {
      grid-template-columns: repeat($i, auto);
      column-gap: 8px;
    }
  }

  @include gridGaps();
}

.grid--column {
  display: flex;
  gap: 8px;
}
