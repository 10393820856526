@import "colors";
@import "helper";
@import "border";

.page-header {
  @include items-center();
  margin-bottom: 46px;
  display: flex;
  justify-content: space-between;

  &--label {
    display: flex;
    column-gap: 21px;
  }

  &--info {
    font-size: 13px;
    font-weight: 500;
    color: $color-text-secondary;
  }
}

.box-shadow {
  @include box-shadow();
}

.value--item {
  @include cart-border--white();
  border-radius: 3px !important;
  display: inline-block;

  .inner-content {
    padding: 0px 12px;
    line-height: 26px;

    .value {
      font-size: 16px;
      line-height: 26px;
      font-weight: 700;
      color: $color-text-dark-dark;
    }
    .unit {
      font-size: 13px;
      line-height: 26px;
      font-weight: 500;
      color: $color-text-dark-dark;
    }
  }
}
