.monitoring-card__total {
  &--inner {
    text-align: center;
    line-height: 36px;
  }

  &--value {
    font-size: 23px;
    font-weight: 700;
  }

  &--unit {
    font-size: 13px;
    font-weight: 500;
    padding-left: 4px;
  }
}

.portfolio-card__total {
  &--inner {
    // text-align: center;
    line-height: 52px;
    justify-content: center;
    display: flex;
  }

  &--value {
    font-size: 33px;
    font-weight: 700;
  }

  &--unit {
    font-size: 19px;
    font-weight: 700;
    padding-left: 4px;
  }
}

.table__total {
  &--inner {
    padding: 0px 12px;
    line-height: 26px;
  }

  &--value {
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    color: $color-text-dark-dark;
  }

  &--unit {
    padding-left: 2px;
    font-size: 13px;
    line-height: 26px;
    font-weight: 500;
    color: $color-text-dark-dark;
  }
}
