@import "colors";

.dropdown-item {
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: $color-link;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;

  &:hover {
    background: $color-bg-active;
    border-radius: 6px;
    color: $color-cta;
  }

  &.active {
    color: $color-cta;
  }
}
