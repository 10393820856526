// primary colors
$color-cta: #0071f0;
$color-link: #003978;
$color-text-dark: #181c42;
$color-text-dark-dark: #0c1246;
$color-text-secondary: #616c79;
$color-text-tertiary: #7f8d9e;
$color-bg-light: #f4f7f9;
$color-bg-semilight: #e9eef3;
$color-bg-medium: #e1e6ec;
$color-bg-active: #dbebfd;
$color-bg-active-light: #ebf5ff;

$color-checkbox-active: #484a58;
$color-radiobutton-active: #484a58;
$color-input-unit: #484a58;

$color-divider: #a9b4c0;
$color-average-user: #67b26c;
$color-average-market: #3d6540;

$color-success: #00960a;
$color-error: #e63f0c;

$color-white: #fff;
$color-bg-body: $color-white;

.color--white {
	color: $color-white;
}

$color-text-grey: #484a58;
$color-main: #0071f0;

$color-form-disabled: #e5e9ec;
$color-text-disabled: #adb0b7;

$color-border: #adb0b7;
$color-border-active: $color-cta;
$color-border-disabled: #e5e9ec;
$color-border-error: $color-error;
$color-icon-active: #adb0b7;
$color-icon-disabled: #e5e9ec;

$color-button-bg-disabled: #e5e9ec;
$color-button-text-disabled: #adb0b7;

$color-notification-warning: #e63f0c;
$color-notification-confirmation: #00960a;
$color-notification-alert: #ff9211;

$color-type-cost: #357091;

// ranking colors
$color-consumption-bar: #9bb9c9;
$color-consumption-label: $color-text-dark;

// chart heating
$color-chart-red-1: #ff5050;
$color-chart-red-2: #b85353;
$color-chart-red-3: #c53b3b;
$color-chart-red-4: #883f3f;

// brand colors

$color-brand-blue-01: #003978;
$color-brand-blue-02: #0055b4;
$color-brand-blue-03: #0071f0;
$color-brand-blue-04: #80b8f8;
$color-brand-blue-05: #badafc;
$color-brand-blue-06: #dbebfd;

$color-brand-green-01: #84b400;
$color-brand-green-02: #007855;

@mixin border-bottom {
	border-bottom: 1px solid $color-brand-blue-01 !important;
}

@mixin hover {
	color: $color-cta;

	img {
		filter: brightness(0) saturate(100%) invert(39%) sepia(59%)
			saturate(6515%) hue-rotate(200deg) brightness(96%) contrast(101%);
	}
}
