@import "colors";
@import "titles";

// items aligned on the same height center center
@mixin items-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

// items aligned on same height on the left
@mixin items-aligned {
  display: inline-flex;
  align-items: center;
}

.items-aligned {
  @include items-aligned();
}

@mixin text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin box-shadow {
  box-shadow: 2px 4px 12px rgba(233, 238, 243, 0.9);
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -100%);
}

.break {
  flex-basis: 100%;
  height: 0;
}

.box {
  border-radius: 4px;
  border: 1px solid $color-bg-semilight;
  background: $color-bg-light;
  width: 100%;
  display: flex;
  gap: 8px;
  @include h3();
  @include items-center();
}
