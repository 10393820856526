@import "colors";
@import "helper";

@mixin border {
  border: 1px solid $color-bg-medium;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
  border-radius: px2r(8);
}

@mixin cart-border--white {
  border: 1px solid $color-bg-medium;
  border-radius: 6px;
  box-sizing: border-box;

  @include box-shadow();
  border-radius: px2r(8);
}

@mixin cart-border--dark {
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(12, 18, 70, 0.25);
  border-radius: px2r(8);
}

@mixin cart-border--cta {
  border: 1px solid $color-cta;
  box-sizing: border-box;
  box-shadow: 2px 4px 12px rgba(0, 113, 240, 0.2);
  border-radius: px2r(8);
}

@mixin card-border--light {
  @include border();
  box-shadow: 2px 4px 12px 0 rgba(233, 238, 243, 0.90);
}

@mixin card-link {
  border: 1px solid transparent;
  box-shadow: 2px 4px 12px 0 rgba(233, 238, 243, 0.90);
  border-radius: 4px;
}
