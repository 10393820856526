@import "colors";

.emission-balance {
  &--value-user {
    font-size: 33px;
    line-height: 36px;
    font-weight: 700;
    color: $color-average-user;
  }

  &--value-market {
    font-size: 33px;
    line-height: 36px;
    font-weight: 700;
    color: $color-average-market;
  }

  &--unit {
    padding-left: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: $color-text-dark-dark;
  }
}
