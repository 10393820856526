@import "disabled";

.ngx-file-drop__drop-zone {
  background: #ebf5ff !important;
  border: 1px solid #dbebfd !important;
  box-shadow: inset 0px 0px 8px rgba(12, 18, 70, 0.1) !important;
  border-radius: 4px !important;
  height: auto !important;

  &:hover {
    border: 2px dotted blue;
  }

  &:disabled {
    @include disabled();
  }
}

.ngx-file-drop__content {
  height: auto !important;
}
