@import "functions";

.spacing--8 {
  margin-bottom: space(8);
}

.spacing--16 {
  margin-bottom: space(16);
}

.spacing--24 {
  margin-bottom: space(24);
}

.spacing--48 {
  margin-bottom: space(48);
}

.mb-l {
  margin-bottom: 48px;
}

.mb-xl {
  margin-bottom: 72px;
}
