.pull-right {
  justify-content: flex-end;
}

.pull-left {
  justify-content: flex-start;
}

.text-right {
  text-align: right;
}
