@import 'colors';

.custom-tooltip {
  p,
  h4 {
    color: $color-white !important;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 500;
  }

  p {
    display: flex;
    gap: 4px;

    .value {
      font-weight: 700;
    }
  }

  &--small {
    width: 150px;
  }

  .descriptive-text {
    font-size: 13px;
    font-weight: 500;
  }
}
