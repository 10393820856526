@import "functions";
@import "colors";

$font-standard: "TheSansE5-Plain", Arial;

@mixin font-basic {
  font-family: $font-standard;
  font-style: normal;
  font-weight: 500;
  font-size: px2r(16);
  line-height: px2r(16);
  color: $color-text-dark;
}
