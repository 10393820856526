@import "fonts";
@import "colors";

a {
  font-family: $font-standard;
  font-style: normal;
  font-weight: 700;
  color: $color-cta;
  text-decoration: none;
  cursor: pointer;
}
