@import "colors";

@mixin disabled($color: $color-text-disabled) {
  &.disabled {
    color: $color !important;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.disabled {
  color: $color-text-disabled !important;
  cursor: not-allowed;
  pointer-events: none;
}
