
@font-face {
  font-family: 'IstaTheSans';
  src: url('istathesans-webfont.woff2') format('woff2'),
  url('istathesans-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IstaTheSans';
  font-weight: bold;
  src:  url('IstaTheSansBold.ttf') format('truetype');
}
@font-face {
  font-family: 'IstaTheSans';
  font-style: italic;
  font-weight: bold;
  src:  url('IstaTheSansBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'IstaTheSans';
  font-style: italic;
  src:  url('IstaTheSansItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'IstaProforma-Book';
  src:  url('IstaProforma-Book.ttf') format('truetype');
}
@font-face {
  font-family: 'IstaProforma-BookItalic';
  src:  url('IstaProforma-BookItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'IstaProforma-Semibold';
  src:  url('IstaProforma-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'IstaProforma-SemiboldItalic';
  src:  url('IstaProforma-SemiboldItalic.ttf') format('truetype');
}

/**
 * UI Base Style Fonts
 **/


/** ExtraLight */
@font-face {
  font-family: 'TheSansE5-ExtraLight';
  src:  url('TheSansE5-2_ExtraLight.woff2') format('truetype');
}

/** Light */
@font-face {
  font-family: 'TheSansE5-Light';
  src:  url('TheSansE5-3_Light.woff2') format('truetype');
}

/** SemiLight */
@font-face {
  font-family: 'TheSansE5-SemiLight';
  src:  url('TheSansE5-4_SemiLight.woff2') format('truetype');
}

/** Plain - Default font */
@font-face {
  font-family: 'TheSansE5-Plain';
  src:  url('TheSansE5-5_Plain.woff2') format('truetype');
}

/** SemiBold */
@font-face {
  font-family: 'TheSansE5-SemiBold';
  src:  url('TheSansE5-6_SemiBold.woff2') format('truetype');
}

/** Bold */
@font-face {
  font-family: 'TheSansE5-Bold';
  src:  url('TheSansE5-7_Bold.woff2') format('truetype');
}

/** ExtraBold */
@font-face {
  font-family: 'TheSansE5-ExtraBold';
  src:  url('TheSansE5-8_ExtraBold.woff2') format('truetype');
}

/** Black */
@font-face {
  font-family: 'TheSansE5-Black';
  src:  url('TheSansE5-9_Black.woff2') format('truetype');
}
